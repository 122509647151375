import React from 'react'
import { makeStyles } from "@material-ui/core/styles"
import Grid from '@material-ui/core/Grid'

const useStyles = makeStyles((theme) => ({
    prizeContainer:{
        backgroundColor: "#FFF",
        width: "100%",
        flexGrow: 1,
        justifyContent: "center",
        marginTop: 80,
        textAlign: "center",
    },

    prizeText:{
        display: "flex",
        justifyContent: "center"
    },

    prizeHead:{
        fontSize: "42px",
        position: "relative",
        margin: "0",
        boxSizing: "border-box",
        '&:before': {
            left: "0",
            width: "100%",
            boxSizing: "border-box",
            bottom: "5px",
            height: "10px",
            opcity: "1",
            content: '""',
            zIndex: "0",
            position: "absolute",
            background: "#ee3342",
            opacity: "30%",
            textAlign: "center",
            justifyContent: "center"
        },
    },

    prizesContainer:{
        display: "flex",
        marginTop: 40,
        textAlign: "center",
        justifyContent: "center",
        [theme.breakpoints.down('sm')]: {
            marginTop: "20px",
        },
    },

    prizePrice:{
        fontSize: "24px",
        fontWeight: "bold",
        margin: "0px"
    },

    firstPrizeImage: {
        [theme.breakpoints.down('sm')]: {
            width: "96px",
            height: "96px",
        },
        [theme.breakpoints.up('sm')]: {
            width: "128px",
            height: "128px",
        },
    },
    secondPrizeImage: {
        [theme.breakpoints.down('sm')]: {
            width: "82px",
            height: "82px",
        },
        [theme.breakpoints.up('sm')]: {
            width: "112px",
            height: "112px",
        },
    },
    thirdPrizeImage: {
        [theme.breakpoints.down('sm')]: {
            width: "72px",
            height: "72px",
        },
        [theme.breakpoints.up('sm')]: {
            width: "96px",
            height: "96px",
        },
    },
    privatePrize:{
        fontWeight: "bold",
    }
}))

export default function Prize(props) {
    const classes = useStyles(props)
    return (
        <div className={classes.prizeContainer}>
            <div className={classes.prizeText} id="oduller">
                <h2 className={classes.prizeHead}>Ödüller</h2>
            </div>
            <div className={classes.prizesContainer}>
                <Grid className={classes.prize} xs={4}>
                <img className={classes.secondPrizeImage} width={112} height={112} src={process.env.PUBLIC_URL + '/static/img/second.png'} alt="" />
                    <p>Setur ödülü</p>
                    <p className={classes.prizePrice}>5000₺</p>
                    <p>Aws Ödülü</p>
                    <p className={classes.privatePrize}>250$ AWS kredisi/Takım üyesi</p>
                </Grid>
                <Grid className={classes.prize} xs={4}>
                <img className={classes.firstPrizeImage} width={128} height={128} src={process.env.PUBLIC_URL + '/static/img/first.png'} alt="" />
                    <p>Setur ödülü</p>
                    <p className={classes.prizePrice}>8000₺</p>
                    <p>Aws Ödülü</p>
                    <p className={classes.privatePrize}>500$ AWS kredisi/Takım üyesi</p>
                </Grid>
                <Grid className={classes.prize} xs={4}>
                <img className={classes.thirdPrizeImage} width={96} height={96} src={process.env.PUBLIC_URL + '/static/img/third.png'} alt="" />
                    <p>Setur ödülü</p>
                    <p className={classes.prizePrice} variant="h3">3000₺</p>
                    <p>Aws Ödülü</p>
                    <p className={classes.privatePrize}>100$ AWS kredisi/Takım üyesi</p>
                </Grid>
            </div>
            <div className={classes.prizesContainer}>
                <Grid className={classes.prize} xs={5}>
                <img className={classes.thirdPrizeImage} width={96} height={96} src={process.env.PUBLIC_URL + '/static/img/winner.png'} alt="" />
                    <p className={classes.prizePrice} variant="h3">Jüri özel ödülü</p>
                    <p>Koç Üniversitesi Girişimcilik Merkezi Kworks 2021 Ön Hızlandırma Programına katılım hakkı.</p>
                </Grid>
            </div>
        </div>
    )
}
