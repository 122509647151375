import React from 'react'
import {makeStyles} from '@material-ui/core/styles'
import Navigation from './Navigation'
import Header from './Header'
import Prize from './Prize'
import Program from './Program'
import Jury from './Jury'
import Partners from './Partners'
import Timeline from './Timeline'
import ForCompetitors from './ForCompetitors'
import FAQ from './FAQ'
import Footer from './Footer'

const useStyles = makeStyles((theme) => ({
    mainWrapper: {
        display: "flex",
        justifyContent: "center",
        [theme.breakpoints.down('sm')]: {
            paddingRight: "10px",
            paddingLeft: "10px",
            display: "block"
        },

        [theme.breakpoints.up('sm')]:{
            paddingRight: "75px",
            paddingLeft: "75px",
        },

        [theme.breakpoints.up('md')]:{
            paddingRight: "100px",
            paddingLeft: "100px",
        },
    },
    wrapper: {
        [theme.breakpoints.up('lg')]:{
            maxWidth: "960px",
        }
    }
    
}))


export default function Landing(props) {
    const classes = useStyles(props)
    return (
        <div className={classes.mainWrapper}>
            <div className={classes.wrapper}>
                <Navigation/>
                <Header/>
                <Prize/>
                <Program/>
                <Timeline/>
                <ForCompetitors/>
                <Jury/>
                <Partners/>
                <FAQ/>
                <Footer/>
            </div>
        </div>
    )
}
