import React from 'react'
import { makeStyles } from "@material-ui/core/styles"
import Button from '@material-ui/core/Button'

const useStyles = makeStyles((theme) => ({
    footerContainer:{
        backgroundColor: "#FFF",
        display: "flex",
        with: "100vh",
        paddingTop: "30px",
        justifyContent: "space-between",
        alignItems: "center",
        flexGrow: 1,
        display: "grid",
        gridTemplateColumns: "auto auto",
        columnGap: "10px",
        height: "100px",
        [theme.breakpoints.down('lg')]: {
            paddingRight: "20px",
            paddingLeft: "20px"
        },
    },

    rightContainer:{
        borderRadius: "10px",
        display: "flex"
    },

    footerLogoKoc:{
        width: "72px",
        height: "auto"
    },

    footerLogoXathon:{
        height: "40px",
    },

    conductText:{
        margin: "0",
        lineHeight: "40px",
    },

    conduct:{
        display: "flex",
    },

    socialMedia:{
        display:"flex",
        justifyContent: "center",
        height: "60px",
        alignItems: "center",
        flexGrow: 1,
        columnGap: "20px",
        [theme.breakpoints.down('xs')]: {
            width: "100%",
            overflowX: "initial",
        },
    },

    socialMediaIcon:{
        color: "#000",
        fontSize: "32px",
    },

    subFooterContainer:{
        backgroundColor: "#FFF",
    }


}))

export default function Footer(props) {
    const classes = useStyles(props)
    return (
        <div>
            <div className={classes.footerContainer}>
               <div className={classes.leftContainer}>
                   <img className={classes.footerLogoKoc} src={process.env.PUBLIC_URL + '/static/img/logo/koc-logo.jpg'} alt="koç logo" />
               </div>

               <div className={classes.rightContainer}>
                    
                    <div className={classes.conduct}>
                        <p className={classes.conductText}>Conduct by </p><a href="http://xathon.co"><img className={classes.footerLogoXathon} src={process.env.PUBLIC_URL + '/static/img/logo/xathon-logo.png'} alt="xathon logo" /></a>
                    </div>
               </div>
            </div>
            <div className={classes.subFooterContainer}>
                <div className={classes.socialMedia}>
                    <a href="https://www.instagram.com/setur/" className={classes.socialMediaIcon}>
                        <i class="fab fa-instagram-square"></i>
                    </a>
                    <a href="https://www.facebook.com/setur" className={classes.socialMediaIcon}>
                        <i class="fab fa-facebook-square"></i>
                    </a>
                    <a href="https://www.linkedin.com/company/setur/" className={classes.socialMediaIcon}>
                        <i class="fab fa-linkedin"></i>
                    </a>
                </div>
            </div>
        </div>
    )
}
