import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  navigationContainer: {
    backgroundColor: "#FFF",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    top: 0,
    height: "70px",
    [theme.breakpoints.up("lg")]: {
      width: "960px",
      position: "fixed",
    },
    zIndex: "999",
  },

  rightContainer: {
    borderRadius: "10px",
  },

  navigationRegisterButton: {
    background: "#FF3644",
    borderRadius: 10,
    border: 0,
    color: "white",
    height: 35,
    padding: "0 30px",
    boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
    "&:hover": {
      backgroundColor: "#FF3644",
    },
    textDecoration: "none",
  },

  headerRightImage: {
    height: "30px",
  },

  navigationLink: {
    fontSize: "14px",
    fontWeight: "bold",
    fontFamily: "Helvetica",
    color: "#24252A",
    textDecoration: "none",
  },

  wrapper: {
    [theme.breakpoints.up("lg")]: {
      maxWidth: "960px",
    },
  },

  navigationLinksWrapper: {
    display: "grid",
    gridTemplateColumns: "auto auto auto auto auto",
    columnGap: "30px",
    marginRight: "45px",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },

  navigationRightSide: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

export default function Navigation(props) {
  const classes = useStyles(props);

  return (
    <div className={classes.wrapper}>
      <div className={classes.navigationContainer}>
        <div className={classes.brandContainer}>
          <img
            className={classes.headerRightImage}
            src={process.env.PUBLIC_URL + "/static/img/logo/setur-logo.png"}
            alt=""
          />
        </div>
        <div className={classes.navigationRightSide}>
          <div className={classes.navigationLinksWrapper}>
            <div className={classes.navigationLinkContainer}>
              <a href="#oduller" className={classes.navigationLink}>
                Ödüller
              </a>
            </div>

            <div className={classes.navigationLinkContainer}>
              <a href="#hackathon" className={classes.navigationLink}>
                Hackathon
              </a>
            </div>

            <div className={classes.navigationLinkContainer}>
              <a href="#juriler" className={classes.navigationLink}>
                Jüriler
              </a>
            </div>

            <div className={classes.navigationLinkContainer}>
              <a href="#partnerler" className={classes.navigationLink}>
                Partnerler
              </a>
            </div>

            <div className={classes.navigationLinkContainer}>
              <a href="#sss" className={classes.navigationLink}>
                S.S.S.
              </a>
            </div>
          </div>

          <div className={classes.rightContainer}>
            <Link
              to="/kaydol-setur-hackathon"
              style={{ textDecoration: "none" }}
            >
              <Button className={classes.navigationRegisterButton}>
                Kaydol
              </Button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
