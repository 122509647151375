import React from 'react'
import { makeStyles } from "@material-ui/core/styles"
import Button from '@material-ui/core/Button'
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';


const useStyles = makeStyles((theme) => ({
    FAQContainer:{
        backgroundColor: "#FFF",
        with: "100vh",
        paddingBottom: "50px",
        flexGrow: 1,
        justifyContent: "center",
        marginTop: 30,
        borderRadius: "10px",
       
    },

    FAQText:{
        paddingTop: "20px",
        display: "flex",
        justifyContent: "center",
    },

    FAQHead:{
        fontSize: "42px",
        position: "relative",
        margin: "0",
        boxSizing: "border-box",
        color: "#24252A",
        '&:before': {
            left: "0",
            width: "100%",
            boxSizing: "border-box",
            bottom: "5px",
            height: "10px",
            opcity: "1",
            content: '""',
            zIndex: "0",
            position: "absolute",
            background: "#ee3342",
            opacity: "30%",
            textAlign: "center",
            justifyContent: "center",
        },
    },
    questionsContainer:{
        marginTop: "40px",
        textAlign: "left",
    },
    heading:{
        fontWeight: "bold",
    }
}))

export default function FAQ(props) {
    const classes = useStyles(props)
    return (
        <div className={classes.FAQContainer} id="sss">
            <div className={classes.FAQText}>
                <h2 className={classes.FAQHead}>S.S.S.</h2>
            </div>
            <div className={classes.questionsContainer}>
            <Accordion>
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                >
                <Typography className={classes.heading}>Setur Travel Smart Tech nedir?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                <Typography>
                Setur Travel Smart Tech, fark yaratan seyahat fikirleri, akıllı turizm uygulamaları, makine öğrenmesi, derin öğrenme, veri madenciliği, doğal dil işleme gibi yapay zekâ uygulamalarını geliştirmek amaçlı bir yarışmadır.
                </Typography>
                </AccordionDetails>
            </Accordion>

            <Accordion>
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                >
                <Typography className={classes.heading}>Smart Tech online mı olacak?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                <Typography>
                Evet, Smart Tech online olacaktır ve bütün görüşmeler Microsoft Teams üzerinden gerçekleşecektir.
                </Typography>
                </AccordionDetails>
            </Accordion>

            <Accordion>
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                >
                <Typography className={classes.heading}>Yarışmada hangi veriler kullanılabilir?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                <Typography>
                Setur.com.tr, Tripadvisor.com, Hotels.com gibi turizm sitelerinden elde edebileceğiniz veriler ile ihtiyaç duyduğunuz başka veri setlerini (hava durumu, yolcu istatistikleri, döviz kuru vb.) kullanabilirsiniz. 
                </Typography>
                </AccordionDetails>
            </Accordion>

            <Accordion>
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                >
                <Typography className={classes.heading}>Yazılımlar hangi dilde geliştirilecek?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                <Typography>
                İstenilen dil ve geliştirme platformu kullanabilirsiniz.
                </Typography>
                </AccordionDetails>
            </Accordion>

            <Accordion>
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                >
                <Typography className={classes.heading}>Yarışma öncesinde projemin yazılımını geliştirebilir miyim?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                <Typography>
                Hayır, Yazılım geliştirme yalnızca etkinlik zamanında yapılabilir. Geçmişte yaptığınız veya farklı bir etkinlikte sunduğunuz projeler diskalifiye edilir.
                </Typography>
                </AccordionDetails>
            </Accordion>

            <Accordion>
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                >
                <Typography className={classes.heading}>Etkinliğe katılım ücretli mi?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                <Typography>
                Katılımcılardan herhangi bir ücret talep edilmeyecektir.
                </Typography>
                </AccordionDetails>
            </Accordion>

            <Accordion>
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                >
                <Typography className={classes.heading}>Etkinliğe nasıl kaydolabilirim?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                <Typography>
                smarttech.setur.com.tr üzerinden kayıt formunu 10 Aralık'a kadar eksiksiz doldurmanız yeterlidir. Başvurusu alınan takımlara onay e-maili gönderilecektir.
                </Typography>
                </AccordionDetails>
            </Accordion>

            <Accordion>
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                >
                <Typography className={classes.heading}>Etkinliğe Setur çalışanları ve stajyerleri başvurabilir mi?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                <Typography>
                Setur çalışanları ve stajyerleri bu etkinliğe katılamayacaktır.
                </Typography>
                </AccordionDetails>
            </Accordion>

            <Accordion>
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                >
                <Typography className={classes.heading}>Projem ve projemin fikri mülkiyeti kime ait olacak?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                <Typography>
                Başvuru Şartlarında yer almaktadır.
                </Typography>
                </AccordionDetails>
            </Accordion>

            <Accordion>
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                >
                <Typography className={classes.heading}>Takımlar kaç kişi olmalıdır?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                <Typography>
                Takımlar en az 3 kişi, en fazla 4 kişi olabilir. Takımda en az bir yazılımcı bulunmalıdır.
                </Typography>
                </AccordionDetails>
            </Accordion>

            <Accordion>
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                >
                <Typography className={classes.heading}>Değerlendirme şartları nelerdir?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                <Typography>
                Setur Travel Smart Tech’in seçkin jürisi 3 hafta sonundaki çalışmaları titizlikle değerlendirecektir.<br/>
                <b>Fikir</b>: Fikriniz Neden Değerli? Ne kadar yenilikçi? Fikir ne kadar uygulanabilir? Turizm sektörüne ne kadar uygun?<br/>
                <b>MVP / Tasarlanan ürün</b>: Fikir sonucu ortaya çıkan ürün çözüme ne kadar uygun? Hangi teknolojiler ne düzeyde kullanıldı?<br/>
                <b>Yapılabilirlik ve Etki</b>: Fikrin hayata geçmesi için kaynaklar nelerdir? Hayata geçirildiğinde nasıl bir etkisi olacak?<br/>
                <b>Sunum ve Demo</b>: Sunumun zamanında teslim edildi mi? Sunum yetkinliği ne düzeyde idi?
                </Typography>
                </AccordionDetails>
            </Accordion>

            <Accordion>
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                >
                <Typography className={classes.heading}>Hackathona kimler katılabilir?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                <Typography>
                Teknoloji ve inovasyona ilgi duyan üniversite öğrencileri veya mezunları katılabilir.
                </Typography>
                </AccordionDetails>
            </Accordion>

            <Accordion>
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                >
                <Typography className={classes.heading}>Ödüller nelerdir?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                <Typography>
                1. Olan takıma Setur tarafından 8000₺ nakit para ödülü ve takımın her üyesine 500$'lık AWS kredisi verilecektir.<br/>
                2. Olan takıma Setur tarafından 5000₺ nakit para ödülü ve takımın her üyesine 250$'lık AWS kredisi verilecektir.<br/>
                3. Olan takıma Setur tarafından 3000₺ nakit para ödülü ve takımın her üyesine 100$'lık AWS kredisi verilecektir.<br/>
                Jüri Özel Ödülü: Her yıl 500’ün üzerinde başvuru arasından 2 aşamalı bir eleme ile 10-12 girişimin kabul edildiği, teknolojik altyapı, bire bir mentorluk, kurumsal bağlantılar, yatırımcı buluşmaları gibi desteklerin sağlandığı, Koç Üniversitesi Girişimcilik Merkezi-KWORKS’ün 2021 yılı Ön Hızlandırma programına direk katılım.
                </Typography>
                </AccordionDetails>
            </Accordion>
            </div>
        </div>
    )
}
