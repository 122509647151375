import logo from "./logo.svg";
import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Landing from "./components/landing/Landing";
import Apply from "./components/apply/Apply";
import GDPR from "./components/apply/GDPR";
import SeturHackathon from "./components/SeturHackathon";

function App() {
  return (
    <div className="App">
      <Router>
        <Switch>
          <Route exact path="/">
            <Landing />
          </Route>
          {/*   <Route exact path="/kaydol">
            <Apply />
          </Route> */}
          <Route exact path="/kaydol-setur-hackathon">
            <SeturHackathon />
          </Route>
          <Route exact path="/kvkk">
            <GDPR />
          </Route>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
