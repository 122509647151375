import React from 'react'
import { makeStyles } from "@material-ui/core/styles"
import Button from '@material-ui/core/Button'
import { Link } from "react-router-dom"

const useStyles = makeStyles((theme) => ({
    gdprContainer:{
        backgroundColor: "#FFF",
        width: "90%",
        textAlign: "left",
        paddingRight: "30px",
        paddingLeft: "30px",
        marginTop: "20px",
    },
}));

export default function GDPR(props) {
    const classes = useStyles(props)

    return (
        <div className={classes.gdprContainer}>
            <h3>SETUR SERVİS TURİSTİK A.Ş. SETUR TRAVEL SMART TECH YARIŞMA PROJESİ KATILIMCI AYDINLATMA METNİ</h3>
            <div className={classes.gdprContentContainer}>
                <h4>1. Veri Sorumlusu</h4>
                <p>Kişisel verileriniz veri sorumlusu sıfatıyla Setur Servis Turistik A.Ş. (“Setur” veya “Şirket” olarak
                    anılacaktır) tarafından, 6698 sayılı Kişisel Verilerin Korunması Kanunu (“Kanun”) uyarınca aşağıda
                    açıklanan kapsamda işlenebilecektir.</p>
            </div>

            <div className={classes.gdprContentContainer}>
                <h4>2. Kişisel Verilerinizin İşlenme Amacı ve Dayanılan Veri İşleme Şartları</h4>
                <p>Toplanan kişisel verileriniz belirtilen kanuni veri işleme şartına (“hukuki sebep”) dayalı olarak ve ifade edilen amaçlarla işlenebilecektir;</p>
                <p>Bir sözleşmenin kurulması veya ifasıyla doğrudan doğruya ilgili olması kaydıyla, sözleşmenin
taraflarına ait kişisel verilerin işlenmesinin gerekli olması hukuki sebebine dayalı olarak; o
Şirket tarafından yürütülen Setur Travel Smart Tech yarışma organizasyonu
faaliyetlerinin gerçekleştirilmesi; <br></br>
                - Başvurunuzun toplanması ve değerlendirme kriterlerine uygunluğunun ölçümlenmesi
                - Yarışma kapsamında görüşmelerin düzenlenmesi ve gerçekleştirilmesi
                - Başvurunuz hakkında tarafınızla gerekli iletişimlerin sağlanması için ilgili iş birimlerimiz tarafından gerekli çalışmaların yapılması ve buna bağlı iş süreçlerinin yürütülmesi
                - Ödül kazanan katılımcıların adreslerine gerekli ödüllerin iletilmesi</p>
                <p>Açık rızanızın bulunması hukuki sebebine dayalı olarak;<br/>
                - Yarışma organizasyonunun dijital ortamda gerçekleştirilmesi esnasında kullanılan teknik altyapının yurt dışında bulunan hizmet sağlayıcılarından tedariki esnasında kişisel verilerinizin yurt dışına aktarımı
                - Etkinliğe katılımız esnasında ses ve görüntü kayıtlarınızın elde edilmesi ve etkinliğin ve Şirketimizin tanıtımı amacıyla hazırlanacak içeriklerde bu kayıtların kullanılması ile söz konusu tanıtıcı içeriklerin kamuya açık mecralarda paylaşılması
                </p>
            </div>

            <div className={classes.gdprContentContainer}>
                <h4>3. Kişisel Verilerinizin Aktarıldığı Taraflar</h4>
                <p>Toplanan kişisel verileriniz yukarıda belirtilen amaçların gerçekleştirilmesi doğrultusunda başta,
Şirket’in etkinliğin organizasyonu esnasında hizmet aldığı iş ortaklarına, etkinliğin gerçekleştirilmesi
esnasında kullanılan teknik altyapıyı sağlayan Microsoft Inc.’e, kanuni yükümlülükleri doğrultusunda
kanunen yetkili kamu kurum ve kuruluşlarına Kanun’un 8 ve 9 maddelerinde belirtilen kişisel veri
işleme şartları ve amaçları çerçevesinde aktarılabilecektir.</p>
            </div>

            <div className={classes.gdprContentContainer}>
                <h4>4. Kişisel Verilerinizin Toplanma Yöntemi</h4>
                <p>Kişisel verileriniz, ilgili yarışma projesi sürecinde başvurularınızın toplandığı web siteleri, bizlerle
paylaştığınız özgeçmişiniz, video konferans yazılımları üzerinden toplanacaktır. Kişisel verileriniz,
“Kişisel Verilerinizin İşlenme Amacı ve Dayanılan Veri İşleme Şartları” başlığı altında ifade edilen
hukuki sebeplere dayalı olarak toplanmaktadır.</p>
            </div>

            <div className={classes.gdprContentContainer}>
                <h4>5. Kişisel Veri Sahibi Olarak Kanun’un 11 Maddesinde Sayılan Haklarınız</h4>
                <p>Kişisel veri sahibi olarak Kanun’un 11 maddesi uyarınca aşağıdaki haklara sahip olduğunuzu bildiririz:<br/>
                    - Kişisel verilerinizin işlenip işlenmediğini öğrenme,<br/>
                    - Kişisel verileriniz işlenmişse buna ilişkin bilgi talep etme,<br/>
                    - Kişisel verilerinizin işlenme amacını ve kişisel verilerinizin amacına uygun kullanılıp kullanılmadığını öğrenme,<br/>
                    - Yurt içinde veya yurt dışında kişisel verilerinizin aktarıldığı üçüncü kişileri bilme, <br/>
                    - Kişisel verilerinizin eksik veya yanlış işlenmiş olması hâlinde bunların düzeltilmesini isteme ve bu kapsamda yapılan işlemin kişisel verilerinizin aktarıldığı üçüncü kişilere bildirilmesini isteme, <br/>
                    - Kanun ve ilgili diğer kanun hükümlerine uygun olarak işlenmiş olmasına rağmen, işlenmesini gerektiren sebeplerin ortadan kalkması hâlinde kişisel verilerin silinmesini veya yok edilmesini isteme ve bu kapsamda yapılan işlemin kişisel verilerinizin aktarıldığı üçüncü kişilere bildirilmesini isteme, <br/>
                    - İşlenen verilerin münhasıran otomatik sistemler vasıtasıyla analiz edilmesi suretiyle aleyhinize bir sonucun ortaya çıkması durumunda buna itiraz etme, <br/> 
                    - Kişisel verilerinizin kanuna aykırı olarak işlenmesi sebebiyle zarara uğramanız hâlinde zararın giderilmesini talep etme.
                    </p>
            </div>

            <div className={classes.gdprContentContainer}>
                <p>Yukarıda sıralanan haklarınıza yönelik başvurularınızı, https://kvkk.setur.com.tr/izin-iptal adresinden
ulaşabileceğiniz Setur- Veri Sahibi Başvuru Formu’nu doldurarak Şirketimize iletebilirsiniz. Talebinizin
niteliğine göre en kısa sürede ve en geç otuz gün içinde başvurularınız ücretsiz olarak
sonuçlandırılacaktır; ancak işlemin ayrıca bir maliyet gerektirmesi halinde Kişisel Verileri Koruma
Kurulu tarafından belirlenecek tarifeye göre tarafınızdan ücret talep edilebilecektir.</p>
            </div>

        </div>
    )
}
