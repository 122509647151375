import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Form from "./Form";
import Navigation from "./Navigation";
import Footer from "./Footer";

const useStyles = makeStyles((theme) => ({
  mainWrapper: {
    display: "flex",
    justifyContent: "center",
  },
  wrapper: {
    [theme.breakpoints.up("lg")]: {
      maxWidth: "960px",
    },
  },
}));

export default function Apply(props) {
  const classes = useStyles(props);

  return (
    <div className={classes.mainWrapper}>
      <div className={classes.wrapper}>
        <Navigation />

        <Form />

        <Footer />
      </div>
    </div>
  );
}
