import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  navigationContainer: {
    backgroundColor: "#FFF",
    with: "100vh",
    paddingTop: "20px",
    justifyContent: "space-between",
    alignItems: "center",
  },
  wrapper: {
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      paddingRight: "10px",
      paddingLeft: "10px",
      display: "block",
    },

    [theme.breakpoints.up("sm")]: {
      paddingRight: "75px",
      paddingLeft: "75px",
    },

    [theme.breakpoints.up("md")]: {
      paddingRight: "100px",
      paddingLeft: "100px",
    },
  },
  rightContainer: {
    borderRadius: "10px",
  },

  brandContainer: {
    display: "flex",
  },

  headerLeftImage: {
    height: "50px",
    [theme.breakpoints.down("lg")]: {
      marginLeft: "20px",
    },
    [theme.breakpoints.down("sm")]: {
      height: "30px",
    },
  },

  navigationHeadText: {
    lineHeight: "47px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "18px",
      lineHeight: "10px",
    },
  },

  arrow: {
    fontSize: "25px",
  },
  backToHomeButton: {
    border: 0,
    color: "#000",
    height: 45,
    "&:hover": {
      backgroundColor: "#FFF",
    },
  },

  backToHomeContainer: {
    textAlign: "left",
    paddingBottom: 30,
  },

  contentWrapper: {
    marginTop: "150px",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      paddingRight: "10px",
      paddingLeft: "10px",
      display: "block",
    },

    [theme.breakpoints.up("sm")]: {
      paddingRight: "75px",
      paddingLeft: "75px",
    },

    [theme.breakpoints.up("md")]: {
      paddingRight: "100px",
      paddingLeft: "100px",
    },
  },
  formInfo: {
    lineHeight: "15px",
    fontSize: "18px",
    color: "#000000",
    textAlign: "center",
  },
}));

export default function SeturHackathon(props) {
  const classes = useStyles(props);
  return (
    <div>
      <div className={classes.wrapper}>
        <div className={classes.navigationContainer}>
          <div className={classes.brandContainer}>
            <img
              className={classes.headerLeftImage}
              src={process.env.PUBLIC_URL + "/static/img/logo/setur-logo.png"}
              alt=""
            />
          </div>
        </div>
      </div>
      <div className={classes.contentWrapper}>
        <h2>Setur Smarttech Hackathon 2020 sona erdi.</h2>
        <p className={classes.formInfo}>
          Smart Travel 2021'e başvurmak için
          <span className={classes.link}>
            <a href="https://smarttravel.setur.com.tr/"> tıklayınız</a>
          </span>
        </p>
      </div>
    </div>
  );
}
