import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  juryContainer: {
    backgroundColor: "#FFF",
    with: "100vh",
    paddingBottom: "50px",
    flexGrow: 1,
    justifyContent: "center",
    marginTop: 30,
    borderRadius: "10px",
  },

  juryText: {
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.up("sm")]: {
      paddingTop: "20px",
    },
  },

  juryHead: {
    fontSize: "42px",
    position: "relative",
    margin: "0",
    boxSizing: "border-box",
    color: "#24252A",
    "&:before": {
      left: "0",
      width: "100%",
      boxSizing: "border-box",
      bottom: "5px",
      height: "10px",
      opcity: "1",
      content: '""',
      zIndex: "0",
      position: "absolute",
      background: "#ee3342",
      opacity: "30%",
      textAlign: "center",
      justifyContent: "center",
    },
  },

  jurries: {
    [theme.breakpoints.down("sm")]: {
      paddingTop: "5px",
      display: "block",
    },
    [theme.breakpoints.up("sm")]: {
      paddingTop: "20px",
      flexGrow: 1,
      display: "grid",
      gridTemplateColumns: "auto auto",
      columnGap: "20px",
    },
    [theme.breakpoints.up("md")]: {
      flexGrow: 1,
      display: "grid",
      gridTemplateColumns: "auto auto auto auto",
      columnGap: "10px",
    },
  },

  jury: {
    width: "100%",
    borderRadius: "10px",
    boxShadow: "0 1.5px 12px 2px rgba(0, 0, 0, 0.06)",
    marginTop: "35px",
    [theme.breakpoints.down("sm")]: {
      height: "400px",
    },
    [theme.breakpoints.up("sm")]: {
      height: "400px",
    },
    [theme.breakpoints.up("md")]: {
      height: "340px",
    },
  },

  juryImageContainer: {
    [theme.breakpoints.down("sm")]: {
      height: "300px",
    },
    [theme.breakpoints.up("sm")]: {
      height: "300px",
    },
    [theme.breakpoints.up("md")]: {
      height: "230px",
    },
  },

  juryImage: {
    boxSizing: "border-box",
    width: "100%",
    height: "100%",
    borderRadius: "10px",
    padding: "3px",
  },

  juryName: {
    fontSize: "16px",
    color: "#000",
    lineHeight: "15px",
  },

  juryCompany: {
    fontSize: "10px",
    fontWeight: "bold",
    color: "#51504f",
  },
}));

export default function Jury(props) {
  const classes = useStyles(props);

  return (
    <div className={classes.juryContainer} id="juriler">
      <div className={classes.juryText}>
        <h2 className={classes.juryHead}>Jüriler</h2>
      </div>

      <div className={classes.jurries}>
        <div className={classes.jury}>
          <div className={classes.juryImageContainer}>
            <img
              className={classes.juryImage}
              src={
                process.env.PUBLIC_URL + "/static/img/jury/nilay-azizoglu.webp"
              }
              alt=""
            />
          </div>
          <div className={classes.juryNameContainer}>
            <p className={classes.juryName}>Nilay Azizoğlu </p>
            <p className={classes.juryCompany}>
              Setur İş Çözümler, Fikri & Sınai Haklar Müdürü
            </p>
            <a
              href="https://www.linkedin.com/in/nilayazizoglu/"
              target="_blank"
            >
              <i class="fab fa-linkedin"></i>
            </a>
          </div>
        </div>

        <div className={classes.jury}>
          <div className={classes.juryImageContainer}>
            <img
              className={classes.juryImage}
              src={
                process.env.PUBLIC_URL + "/static/img/jury/alphan-kimyonk.webp"
              }
              alt=""
            />
          </div>
          <div className={classes.juryNameContainer}>
            <p className={classes.juryName}>Alphan Kimyonok</p>
            <p className={classes.juryCompany}>
              Setur Bilgi Teknolojileri ve Arge Müdürü
            </p>
            <a
              href="https://www.linkedin.com/in/alphan-kimyonok-b2355618/"
              target="_blank"
            >
              <i class="fab fa-linkedin"></i>
            </a>
          </div>
        </div>

        <div className={classes.jury}>
          <div className={classes.juryImageContainer}>
            <img
              className={classes.juryImage}
              src={
                process.env.PUBLIC_URL + "/static/img/jury/sitki-gokhan.webp"
              }
              alt=""
            />
          </div>
          <div className={classes.juryNameContainer}>
            <p className={classes.juryName}>Sıtkı Gökhan Dedeoğlu</p>
            <p className={classes.juryCompany}>
              Setur Bireysel Online Satış Kanalları Müdürü
            </p>
            <a
              href="https://www.linkedin.com/in/sitki-g%C3%B6khan-dedeo%C4%9Flu-748b93196/"
              target="_blank"
            >
              <i class="fab fa-linkedin"></i>
            </a>
          </div>
        </div>

        <div className={classes.jury}>
          <div className={classes.juryImageContainer}>
            <img
              className={classes.juryImage}
              src={
                process.env.PUBLIC_URL + "/static/img/jury/derya-gulunay.webp"
              }
              alt=""
            />
          </div>
          <div className={classes.juryNameContainer}>
            <p className={classes.juryName}>Derya Gülünay</p>
            <p className={classes.juryCompany}>
              Setur Pazarlama ve Kurumsal İletişim Müdürü
            </p>
            <a
              href="https://www.linkedin.com/in/derya-g%C3%BCl%C3%BCnay-aa479832/"
              target="_blank"
            >
              <i class="fab fa-linkedin"></i>
            </a>
          </div>
        </div>

        <div className={classes.jury}>
          <div className={classes.juryImageContainer}>
            <img
              className={classes.juryImage}
              src={
                process.env.PUBLIC_URL + "/static/img/jury/erdal-sekerci.webp"
              }
              alt=""
            />
          </div>
          <div className={classes.juryNameContainer}>
            <p className={classes.juryName}>Erdal Şekerci</p>
            <p className={classes.juryCompany}>Setur BI Yöneticisi </p>
            <a href="https://www.linkedin.com/in/erdalsekerci/" target="_blank">
              <i class="fab fa-linkedin"></i>
            </a>
          </div>
        </div>

        <div className={classes.jury}>
          <div className={classes.juryImageContainer}>
            <img
              className={classes.juryImage}
              src={
                process.env.PUBLIC_URL + "/static/img/jury/serhat-gunay.webp"
              }
              alt=""
            />
          </div>
          <div className={classes.juryNameContainer}>
            <p className={classes.juryName}>Serhat Günay</p>
            <p className={classes.juryCompany}>
              Setur Yurt İçi Operasyon Müdürü
            </p>
            <a
              href="https://www.linkedin.com/in/serhat-g%C3%BCnay-66360717/"
              target="_blank"
            >
              <i class="fab fa-linkedin"></i>
            </a>
          </div>
        </div>

        <div className={classes.jury}>
          <div className={classes.juryImageContainer}>
            <img
              className={classes.juryImage}
              src={
                process.env.PUBLIC_URL + "/static/img/jury/andac-erarabaci.webp"
              }
              alt=""
            />
          </div>
          <div className={classes.juryNameContainer}>
            <p className={classes.juryName}>Andaç Erarabacı</p>
            <p className={classes.juryCompany}>
              Setur Bireysel Offline Satış Kanalları Müdürü
            </p>
            <a
              href="https://www.linkedin.com/in/andacerarabaci/"
              target="_blank"
            >
              <i class="fab fa-linkedin"></i>
            </a>
          </div>
        </div>

        <div className={classes.jury}>
          <div className={classes.juryImageContainer}>
            <img
              className={classes.juryImage}
              src={
                process.env.PUBLIC_URL +
                "/static/img/jury/gunce-keziban-orman.webp"
              }
              alt=""
            />
          </div>
          <div className={classes.juryNameContainer}>
            <p className={classes.juryName}>
              Dr. Öğr. Üyesi Günce Keziban Orman
            </p>
            <p className={classes.juryCompany}>Galatasaray Üniversitesi </p>
            <a
              href="https://www.linkedin.com/in/keziban-g%C3%BCnce-orman-73b5443/"
              target="_blank"
            >
              <i class="fab fa-linkedin"></i>
            </a>
          </div>
        </div>

        <div className={classes.jury}>
          <div className={classes.juryImageContainer}>
            <img
              className={classes.juryImage}
              src={
                process.env.PUBLIC_URL + "/static/img/jury/ozgun-pinarer.webp"
              }
              alt=""
            />
          </div>
          <div className={classes.juryNameContainer}>
            <p className={classes.juryName}>Dr. Öğr. Üyesi Özgün Pınarer</p>
            <p className={classes.juryCompany}>Galatasaray Üniversitesi </p>
            <a
              href="https://www.linkedin.com/in/ozgun-pinarer-266a01128/"
              target="_blank"
            >
              <i class="fab fa-linkedin"></i>
            </a>
          </div>
        </div>

        <div className={classes.jury}>
          <div className={classes.juryImageContainer}>
            <img
              className={classes.juryImage}
              src={
                process.env.PUBLIC_URL + "/static/img/jury/sultan-turhan.webp"
              }
              alt=""
            />
          </div>
          <div className={classes.juryNameContainer}>
            <p className={classes.juryName}>Öğr. Görevlisi Dr. Sultan Turhan</p>
            <p className={classes.juryCompany}>Galatasaray Üniversitesi </p>
            <a
              href="https://www.linkedin.com/in/sultan-turhan-77855a1b/"
              target="_blank"
            >
              <i class="fab fa-linkedin"></i>
            </a>
          </div>
        </div>

        <div className={classes.jury}>
          <div className={classes.juryImageContainer}>
            <img
              className={classes.juryImage}
              src={
                process.env.PUBLIC_URL + "/static/img/jury/halil-bahadir.webp"
              }
              alt=""
            />
          </div>
          <div className={classes.juryNameContainer}>
            <p className={classes.juryName}>Halil Bahadır</p>
            <p className={classes.juryCompany}>
              Amazon Solutions Architect Manager
            </p>
            <a
              href="https://www.linkedin.com/in/halil-bahadir-6588671/"
              target="_blank"
            >
              <i class="fab fa-linkedin"></i>
            </a>
          </div>
        </div>

        <div className={classes.jury}>
          <div className={classes.juryImageContainer}>
            <img
              className={classes.juryImage}
              src={process.env.PUBLIC_URL + "/static/img/jury/tunc-ozgul.webp"}
              alt=""
            />
          </div>
          <div className={classes.juryNameContainer}>
            <p className={classes.juryName}>Tunç Özgül</p>
            <p className={classes.juryCompany}>
              Amazon Start-up&VC Business Development Manager
            </p>
            <a href="https://www.linkedin.com/in/tuncozgul/" target="_blank">
              <i class="fab fa-linkedin"></i>
            </a>
          </div>
        </div>

        <div className={classes.jury}>
          <div className={classes.juryImageContainer}>
            <img
              className={classes.juryImage}
              src={
                process.env.PUBLIC_URL +
                "/static/img/jury/beril-boyacioglu-bakir.webp"
              }
              alt=""
            />
          </div>
          <div className={classes.juryNameContainer}>
            <p className={classes.juryName}>Beril Boyacıoğlu Bakır</p>
            <p className={classes.juryCompany}>KWORKS Koordinatörü </p>
            <a
              href="https://www.linkedin.com/in/dr-beril-boyac%C4%B1o%C4%9Flu-bak%C4%B1r-5a117655/"
              target="_blank"
            >
              <i class="fab fa-linkedin"></i>
            </a>
          </div>
        </div>

        <div className={classes.jury}>
          <div className={classes.juryImageContainer}>
            <img
              className={classes.juryImage}
              src={
                process.env.PUBLIC_URL + "/static/img/jury/mahmut-ozdemir.webp"
              }
              alt=""
            />
          </div>
          <div className={classes.juryNameContainer}>
            <p className={classes.juryName}>Mahmut Özdemir</p>
            <p className={classes.juryCompany}>KWORKS Direktörü</p>
            <a href="https://www.linkedin.com/in/mnozdemir/" target="_blank">
              <i class="fab fa-linkedin"></i>
            </a>
          </div>
        </div>

        <div className={classes.jury}>
          <div className={classes.juryImageContainer}>
            <img
              className={classes.juryImage}
              src={
                process.env.PUBLIC_URL + "/static/img/jury/meltem-yondem.webp"
              }
              alt=""
            />
          </div>
          <div className={classes.juryNameContainer}>
            <p className={classes.juryName}>Dr. Meltem Turhan Yöndem</p>
            <p className={classes.juryCompany}>Okan Üniversitesi</p>
            <a
              href="https://www.linkedin.com/in/meltem-turhan-y%C3%B6ndem-347a0329/"
              target="_blank"
            >
              <i class="fab fa-linkedin"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
