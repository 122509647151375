import React from 'react'
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles((theme) => ({
    partnerContainer:{
        backgroundColor: "#FFF",
        width: "100%",
        flexGrow: 1,
        justifyContent: "center",
        marginTop: 30,
        paddingTop: 20,
        paddingBottom: 20,
    },

    partnerText:{
        display: "flex",
        justifyContent: "center"
    },

    partnerHead:{
        fontSize: "42px",
        position: "relative",
        margin: "0",
        boxSizing: "border-box",
        '&:before': {
            left: "0",
            width: "100%",
            boxSizing: "border-box",
            bottom: "5px",
            height: "10px",
            opcity: "1",
            content: '""',
            zIndex: "0",
            position: "absolute",
            background: "#ee3342",
            opacity: "30%",
            textAlign: "center",
            justifyContent: "center"
        },
    },

    partnersContainer:{
        width: "100%",
        display: "flex",
        textAlign: "center",
        [theme.breakpoints.up('sm')]: {
            paddingTop: "20px",
        },
        [theme.breakpoints.down('sm')]: {
            paddingTop: "5px",
            display: "block",
            flexGrow: 1,
            gridTemplateColumns: "auto auto",
            rowGap: "30px",
        },
    },

    partner:{
        width: "100%",
        [theme.breakpoints.down('sm')]: {
            display: "block",
            justifyContent: "center",
            marginTop: "30px",
        },
        
    },

    prizeImage:{
        [theme.breakpoints.down('sm')]: {
            justifyContent: "center",
        },
    }
}))

export default function Partners(props) {
    const classes = useStyles(props)
    return (
        <div className={classes.partnerContainer} id="partnerler">
            <div className={classes.partnerText}>
                <h2 className={classes.partnerHead}>Partnerler</h2>
            </div>
            <div className={classes.partnersContainer}>
                <div className={classes.partner}>
                    <img className={classes.prizeImage} width={150} height={75} src={process.env.PUBLIC_URL + '/static/img/partners/aws.png'} alt="" />
                </div>
                <div className={classes.partner}>
                    <img className={classes.prizeImage} width={240} height={75} src={process.env.PUBLIC_URL + '/static/img/partners/kworks.png'} alt="" />
                </div>
            </div>
        </div>
    )
}
