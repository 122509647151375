import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { v4 as uuidv4 } from "uuid";
import Button from "@material-ui/core/Button";
import axios from "axios";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import PhoneInput from "react-phone-number-input";
import { LeakRemoveTwoTone } from "@material-ui/icons";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  formContainer: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },

    [theme.breakpoints.up("md")]: {
      width: "100%",
    },

    [theme.breakpoints.up("lg")]: {
      width: "760px",
    },
  },

  formElements: {
    [theme.breakpoints.down("lg")]: {
      paddingRight: "20px",
      paddingLeft: "20px",
    },
  },

  formInputContainer: {
    paddingTop: "10px",
  },

  formInfo: {
    lineHeight: "15px",
    fontSize: "12px",
    color: "#000000",
    textAlign: "left",
  },

  formInput: {
    marginTop: "3px",
    lineHeight: "15px",
    fontSize: "12px",
    color: "#646464",
    border: "0.5px solid #f3f3f3",
    backgroundColor: "#f3f3f3",
    borderRadius: "10px",
    height: "45px",
    paddingLeft: "10px",
    outline: "none",
    width: "100%",
    boxSizing: "border-box",
  },
  formFile: {
    justifyContent: "start",
  },
  formSubmitButton: {
    marginTop: "50px",
    background: "#FF3644",
    borderRadius: 10,
    border: 0,
    color: "white",
    height: 45,
    width: 175,
    boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
    "&:hover": {
      backgroundColor: "#FF3644",
    },

    [theme.breakpoints.down("sm")]: {
      marginTop: "20px",
    },
  },

  customFileUpload: {
    backgroundColor: "#F3F3F3",
    width: "100%",
    height: "45px",
    borderRadius: "10px",
    lineHeight: "45px",
  },

  hr: {
    color: "#51504f",
    backgroundColor: "#51504f",
  },

  addCompetitor: {
    marginTop: "50px",
    background: "#F3F3F3",
    borderRadius: 10,
    border: 0,
    color: "#51504f",
    height: 45,
    width: 175,
    "&:hover": {
      backgroundColor: "#F3F3F3",
    },

    [theme.breakpoints.down("sm")]: {
      marginTop: "20px",
    },
  },

  twoColumns: {
    flexGrow: 1,
    display: "grid",
    gridTemplateColumns: "auto auto",
    columnGap: "10px",
  },

  formSubmitButtonNotAgree: {
    marginTop: "0px",
    background: "#F3F3F3",
    borderRadius: 10,
    border: 0,
    color: "#000",
    height: 45,
    width: 175,
    "&:hover": {
      background: "#F3F3F3",
    },

    [theme.breakpoints.down("sm")]: {
      marginTop: "0px",
    },
  },

  isAgreeNotification: {
    color: "red",
    fontSize: "14px",
    margin: 0,
    marginTop: 20,
  },

  gdprTextAll: {
    textAlign: "jutify",
    fontSize: "12px",
    color: "green",
    paddingBottom: "10px",
  },

  gdprText: {
    color: "red",
  },

  microSoft: {
    textAlign: "justify",
    fontSize: "12px",
  },

  loadingGif: {
    height: "54px",
    marginTop: "30px",
  },

  gdprTextFirst: {
    textAlign: "jutify",
    fontSize: "12px",
  },
}));

export default function Form(props) {
  const classes = useStyles(props);

  const [teamInfo, setTeamInfo] = useState("");
  const [teamName, setTeamName] = useState("");
  const [users, setUsers] = useState([
    {
      id: uuidv4(),
      name: "",
      surname: "",
      schoolOrCompany: "",
      email: "",
      phoneNumber: "",
      github: "",
      linkedin: "",
      cvsFile: "",
      cvsFileName: "",
      kvkk: false,
    },
    {
      id: uuidv4(),
      name: "",
      surname: "",
      schoolOrCompany: "",
      email: "",
      phoneNumber: "",
      github: "",
      linkedin: "",
      cvsFile: "",
      cvsFileName: "",
      kvkk: false,
    },
    {
      id: uuidv4(),
      name: "",
      surname: "",
      schoolOrCompany: "",
      email: "",
      phoneNumber: "",
      github: "",
      linkedin: "",
      cvsFile: "",
      cvsFileName: "",
      kvkk: false,
    },
  ]);

  const [isRegisterButtonEnable, setIsRegisterButtonEnable] = useState(false);
  const [isRegisterButtonVisible, setIsRegisterButtonVisible] = useState(true);
  const [isAddNewUserButtonVisible, setIsAddNewUserButtonVisible] =
    useState(true);
  const [loadingVisible, setLoadingVisible] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [fileTypeError, setFileTypeError] = useState(false);
  const [fileSizeError, setFileSizeError] = useState(false);
  const [phoneNumberError, setPhoneNumberError] = useState(false);
  const [emailDuplicateError, setEmailDuplicateError] = useState(false);
  const [emailValidation, setEmailValidation] = useState(false);
  const [necessaryField, setNecessaryField] = useState(true);
  const [kvkkCount, setKvkkCount] = useState(0);

  const handleUser = (id, event) => {
    const newUsers = users.map((i) => {
      if (id === i.id) {
        i[event.target.name] = event.target.value;

        if (event.target.type === "checkbox") {
          i[event.target.name] = event.target.checked;
        }

        if (i["phoneNumber"]) {
          if (i["phoneNumber"].charAt(0) != 0) {
            let phoneNumberWithZero = "0" + i["phoneNumber"];
            i["phoneNumber"] = phoneNumberWithZero;
          }
        }

        if (event.target.checked) {
          setKvkkCount(kvkkCount + 1);
        } else {
          setKvkkCount(kvkkCount - 1);
        }

        if (event.target.type === "file") {
          let file = event.target.files[0];
          const fileName = file.name;
          const fileSize = file.size;
          const fileType = fileName.split(".")[1];

          if (fileType == "pdf" || fileType == "doc" || fileType == "docx") {
            if (fileSize / (1024 * 1024) > 5) {
              setFileSizeError(true);
            } else {
              let reader = new FileReader();
              reader.readAsDataURL(file);
              reader.onload = () => {
                i[event.target.name] = file;
                i[event.target.name] = file;
                i["cvsFileName"] = file.name;
              };
            }
          } else {
            setFileTypeError(true);
          }
        }
      }

      return i;
    });
    setUsers(newUsers);
    counter();
  };

  let c = 0;
  const counter = () => {
    users.map((user) => {
      if (user["kvkk"]) {
        c++;
      } else {
        c--;
      }
    });
    if (c == 3 || c == 4) {
      setIsRegisterButtonEnable(true);
    } else {
      setIsRegisterButtonEnable(false);
    }
  };

  const formSubmit = (e) => {
    e.preventDefault();
    setLoadingVisible(true);
    setIsRegisterButtonVisible(false);
    setIsRegisterButtonEnable(false);

    let tokenURI = "https://login.setur.com.tr/connect/token";
    let authorization = "U21hcnRUZWNoOnlWY0BUUTl0RmQ2VDImWA==";
    let bearerToken = "";

    const tokenBodyData = new FormData();
    tokenBodyData.append("grant_type", "client_credentials");
    tokenBodyData.append("username", "Smarttech");
    tokenBodyData.append("password", "yVc@TQ9tFd6T2&X");

    const userBodyData = new FormData();
    const stringifiedUsers = JSON.stringify(users);
    userBodyData.append("TeamName", teamName);
    userBodyData.append("TeamInfo", teamInfo);
    userBodyData.append("Users", stringifiedUsers);

    /* user email push to new array */
    const userEmails = [];
    for (let i = 0; i < users.length; i++) {
      userEmails.push(users[i].email.trim());
    }
    /* console.log(userEmails) */
    /* search duplicates string value in array */
    let findDuplicates = (arr) =>
      arr.filter((item, index) => arr.indexOf(item) != index);
    if (findDuplicates(userEmails).length > 0) {
      /* console.log("found: " + findDuplicates(userEmails)) */
      setLoadingVisible(false);
      setIsRegisterButtonEnable(true);
      setIsRegisterButtonVisible(true);
      return setEmailDuplicateError(true);
    } else {
      users.map((user, index) => {
        if (
          user.name == "" ||
          user.surname == "" ||
          user.schoolOrCompany == "" ||
          user.email == "" ||
          user.cvsFile == ""
        ) {
          return setOpenError(true);
        } else if (user.phoneNumber.length != 11) {
          return setPhoneNumberError(true);
        } else if (!user.email.includes("@")) {
          return setEmailValidation(true);
        } else {
          userBodyData.append("UserCvs", user.cvsFile);
        }
      });
    }

    if (teamName === "") {
      setLoadingVisible(false);
      setIsRegisterButtonEnable(true);
      setIsRegisterButtonVisible(true);
      return setOpenError(true);
    }

    if (teamInfo === "") {
      setLoadingVisible(false);
      setIsRegisterButtonEnable(true);
      setIsRegisterButtonVisible(true);
      return setOpenError(true);
    }

    axios({
      method: "post",
      url: tokenURI,
      data: tokenBodyData,
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "multipart/form-data",
        Authorization: `Basic ${authorization}`,
      },
    })
      .then(function (response) {
        bearerToken = response.data.access_token;
        axios({
          method: "post",
          url: "https://datathon.setur.com.tr/api/teams",
          data: userBodyData,
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${bearerToken}`,
          },
        })
          .then(function (response) {
            const success = response.data.success;

            if (success) {
              setOpenSuccess(true);
              /* console.log("başarılı") */
              setLoadingVisible(false);
              setIsRegisterButtonEnable(false);
              setIsRegisterButtonVisible(true);
              setNecessaryField(false);
            } else {
              setOpenError(true);
              /* console.log("başarısız") */
              setLoadingVisible(false);
              setIsRegisterButtonEnable(true);
              setIsRegisterButtonVisible(true);
            }
          })
          .catch(function (err) {
            setOpenError(true);
            /* console.log("başarısız") */
            setLoadingVisible(false);
            setIsRegisterButtonEnable(true);
            setIsRegisterButtonVisible(true);
          });
      })
      .catch(function (response) {
        //handle error
        console.log(response);
        setLoadingVisible(false);
        setIsRegisterButtonEnable(true);
        setIsRegisterButtonVisible(true);
      });
  };

  const addNewUser = () => {
    setUsers([
      ...users,
      {
        id: uuidv4(),
        name: "",
        surname: "",
        schoolOrCompany: "",
        email: "",
        phoneNumber: "",
        github: "",
        linkedin: "",
        cvsFile: "",
        cvsFileName: "",
        kvkk: false,
      },
    ]);
    c -= 2;
    /* console.log("c: " + c) */
    if (c == 3 || c == 4) {
      setIsRegisterButtonEnable(true);
    } else {
      setIsRegisterButtonEnable(false);
    }
    setIsAddNewUserButtonVisible(false);
  };
  return (
    <div className={classes.formContainer}>
      <form className={classes.formElements} onSubmit={formSubmit}>
        <div className={classes.formInputContainer}>
          <p className={classes.formInfo}>Takım Adı*</p>
          <input
            type="text"
            className={classes.formInput}
            placeholder="Takım Adı"
            onChange={(e) => setTeamName(e.target.value)}
            required
          />
        </div>

        <div className={classes.formInputContainer}>
          <p className={classes.formInfo}>
            Fikriniz turizm sektörüne nasıl bir katkı sağlayacak?*
          </p>
          <textarea
            onChange={(e) => setTeamInfo(e.target.value)}
            className={classes.formInput}
            placeholder="Fikriniz turizm sektörüne nasıl bir katkı sağlayacak? (3-4 cümle ili açıklayın.)"
            row="6"
            required
          />
        </div>

        {users.map((user, i) => (
          <div key={i}>
            <h3>
              {i + 1}. Katıklımcı {i === 0 ? "(Takım Lideri)" : ""}
            </h3>
            <div className={classes.twoColumns}>
              <div className={classes.formInputContainer}>
                <p className={classes.formInfo}>Ad*</p>
                <input
                  name="name"
                  type="text"
                  className={classes.formInput}
                  onChange={(event) => handleUser(user.id, event)}
                  placeholder="Ad"
                  required
                />
              </div>

              <div className={classes.formInputContainer}>
                <p className={classes.formInfo}>Soyad*</p>
                <input
                  name="surname"
                  type="text"
                  className={classes.formInput}
                  onChange={(event) => handleUser(user.id, event)}
                  placeholder="Soyad"
                  required
                />
              </div>
            </div>

            <div className={classes.formInputContainer}>
              <p className={classes.formInfo}>
                Okuduğunuz/mezun olduğunuz üniversite*
              </p>
              <input
                name="schoolOrCompany"
                type="text"
                className={classes.formInput}
                onChange={(event) => handleUser(user.id, event)}
                placeholder="Okuduğunuz/mezun olduğunuz üniversite"
                required
              />
            </div>

            <div className={classes.twoColumns}>
              <div className={classes.formInputContainer}>
                <p className={classes.formInfo}>Email*</p>
                <input
                  name="email"
                  type="email"
                  className={classes.formInput}
                  onChange={(event) => handleUser(user.id, event)}
                  placeholder="Email"
                  required
                />
              </div>

              <div className={classes.formInputContainer}>
                <p className={classes.formInfo}>Telefon*</p>
                <input
                  name="phoneNumber"
                  type="number"
                  className={classes.formInput}
                  placeholder="5xxxxxxxxx"
                  onChange={(event) => handleUser(user.id, event)}
                  required
                />
              </div>
            </div>

            <div className={classes.twoColumns}>
              <div className={classes.formInputContainer}>
                <p className={classes.formInfo}>Github</p>
                <input
                  name="github"
                  type="text"
                  className={classes.formInput}
                  onChange={(event) => handleUser(user.id, event)}
                  placeholder="Github"
                />
              </div>

              <div className={classes.formInputContainer}>
                <p className={classes.formInfo}>LinkedIn</p>
                <input
                  name="linkedin"
                  type="text"
                  className={classes.formInput}
                  onChange={(event) => handleUser(user.id, event)}
                  placeholder="LinkedIn"
                />
              </div>
            </div>

            <div className={classes.formInputContainer}>
              <p className={classes.formInfo}>CV (PDF, Docx - Max 5MB)*</p>
              <label htmlFor={`file-${user.id}`}>
                <div className={classes.customFileUpload}>
                  {user.cvsFileName == ""
                    ? "CV yüklemek için tıklayınız (PDF, Docx - Max 5MB)"
                    : user.cvsFileName}
                </div>
              </label>
              <input
                id={`file-${user.id}`}
                type="file"
                hidden
                name="cvsFile"
                onChange={(event) => handleUser(user.id, event)}
                required
              />
            </div>

            <div style={{ marginTop: "20px" }}>
              <p className={classes.gdprTextFirst}>
                Yukarıda yer alan form kapsamında iletmiş olduğunuz kişisel
                verileriniz Setur Servis Turistik A.Ş. tarafından katılım
                başvurunuzun değerlendirilmesi için işlenebilecektir. Kişisel
                verilerinizin işlenmesi ve bu kapsamdaki haklarınız hakkında
                detaylı bilgi için{" "}
                <a
                  href="https://smarttech.setur.com.tr/kvkk"
                  target="_blank"
                  className={classes.gdprText}
                >
                  {" "}
                  Aydınlatma Metni
                </a>
                ’ni inceleyebilirsiniz.
              </p>
              <input
                type="checkbox"
                className={classes.microSoft}
                name="kvkk"
                checked={user.kvkk}
                id={`agree-${user.id}`}
                onChange={(event) => handleUser(user.id, event)}
              />
              <label htmlFor={`agree-${user.id}`} className={classes.microSoft}>
                {" "}
                Yarışma organizasyonunun dijital ortamda gerçekleştirilmesi
                sırasında kullanılan teknik altyapının yurt dışında bulunan
                hizmet sağlayıcılarından tedariki esnasında kimlik, iletişim ve
                özgeçmiş bilgilerimin bulut bilişim altyapısından faydalanmak
                amacıyla Microsoft Inc.’e aktarılmasını<b> kabul ediyorum.</b>
                {!(i === 0) ? (
                  <span className={classes.gdprTextAll}>
                    {" "}
                    Ayrıca takım lideri olarak bilgilerini aktardığım takım
                    arkadaşlarıma Setur adına
                    <a
                      href="https://smarttech.setur.com.tr/kvkk"
                      target="_blank"
                      className={classes.gdprText}
                    >
                      {" "}
                      Aydınlatma Metni
                    </a>
                    ’ni sunduğumu ve onların da işbu açık rıza metnine onayı
                    bulunduğunu <b>taahhüt ederim.</b>
                  </span>
                ) : (
                  ""
                )}
              </label>
            </div>

            <hr className={classes.hr} />
          </div>
        ))}
        {isAddNewUserButtonVisible && (
          <div className={classes.formInputContainer}>
            <Button className={classes.addCompetitor} onClick={addNewUser}>
              Takıma 4. Üye Ekle
            </Button>
          </div>
        )}
        {isRegisterButtonVisible === true &&
          isRegisterButtonEnable === true &&
          loadingVisible === false && (
            <div className={classes.formInputContainer}>
              <Button className={classes.formSubmitButton} onClick={formSubmit}>
                Kaydol
              </Button>
            </div>
          )}

        {isRegisterButtonVisible === true &&
          isRegisterButtonEnable === false &&
          loadingVisible === false &&
          necessaryField === true && (
            <div>
              <p className={classes.isAgreeNotification}>
                * Alanların doldurulması zorunludur.
              </p>
              <p className={classes.isAgreeNotification}>
                Aydınlatma metnini onaylamanız gerekmektedir.
              </p>
            </div>
          )}

        {isRegisterButtonVisible === true &&
          isRegisterButtonEnable === false &&
          loadingVisible === false && (
            <div>
              <div className={classes.formInputContainer}>
                <Button className={classes.formSubmitButtonNotAgree}>
                  Kaydol
                </Button>
              </div>
            </div>
          )}

        {loadingVisible === true &&
          isRegisterButtonVisible === false &&
          isRegisterButtonEnable === false && (
            <div className={classes.formInputContainer}>
              <img
                className={classes.loadingGif}
                src={process.env.PUBLIC_URL + "/static/img/loading.gif"}
                alt=""
              />
            </div>
          )}
      </form>
      <Snackbar
        open={openSuccess}
        autoHideDuration={6000}
        onClose={() => {
          setOpenSuccess(false);
        }}
      >
        <Alert severity="success">Kayıt başarılı!</Alert>
      </Snackbar>

      <Snackbar
        open={openError}
        autoHideDuration={8000}
        onClose={() => {
          setOpenError(false);
        }}
      >
        <Alert severity="error">* alanları doldurup tekrar deneyiniz.</Alert>
      </Snackbar>

      <Snackbar
        open={fileTypeError}
        autoHideDuration={3000}
        onClose={() => {
          setFileTypeError(false);
        }}
      >
        <Alert severity="error">
          CVleriniz PDF ve Docx formatlarında olması gerekmektedir.
        </Alert>
      </Snackbar>

      <Snackbar
        open={fileSizeError}
        autoHideDuration={3000}
        onClose={() => {
          setFileSizeError(false);
        }}
      >
        <Alert severity="error">
          CVleriniz 5MB'tan küçük olması gerekmektedir.
        </Alert>
      </Snackbar>

      <Snackbar
        open={emailValidation}
        autoHideDuration={3000}
        onClose={() => {
          setEmailValidation(false);
        }}
      >
        <Alert severity="error">Geçersiz email.</Alert>
      </Snackbar>

      <Snackbar
        open={phoneNumberError}
        autoHideDuration={3000}
        onClose={() => {
          setPhoneNumberError(false);
        }}
      >
        <Alert severity="error">
          Telefon numarası 10 haneli olmalıdır başına 0 koymayınız.
        </Alert>
      </Snackbar>

      <Snackbar
        open={emailDuplicateError}
        autoHideDuration={3000}
        onClose={() => {
          setEmailDuplicateError(false);
        }}
      >
        <Alert severity="error">
          Katılımcıların email bilgileri farklı olmalıdır.
        </Alert>
      </Snackbar>
    </div>
  );
}
