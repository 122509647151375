import React from 'react'
import { makeStyles } from "@material-ui/core/styles"
import Button from '@material-ui/core/Button'
import { Link } from "react-router-dom"

const useStyles = makeStyles((theme) => ({
    timelineContainer:{
        backgroundColor: "#FFF",
        with: "100vh",
        display: "flex",
        flexGrow: 1,
        justifyContent: "center",
        marginTop: 30,
        borderRadius: "10px",
        fontFamily: "Roboto"
    },

    timelineImg:{
        [theme.breakpoints.down('sm')]: {
            height: "300px",
        },
        [theme.breakpoints.up('sm')]: {
            height: "400px",
        },
        [theme.breakpoints.up('lg')]: {
            height: "600px",
        },
    },

}))



export default function Timeline(props) {
    const classes = useStyles(props)
    return (
        <div className={classes.timelineContainer}>
            <img className={classes.timelineImg} src={process.env.PUBLIC_URL + '/static/img/timeline.png'} alt="Setur travel smart tech hackathon" />
        </div>
    )
}
