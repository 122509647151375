import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  hackathonContainer: {
    backgroundColor: "#F3F3F3",
    with: "100vh",
    paddingBottom: "50px",
    flexGrow: 1,
    justifyContent: "center",
    marginTop: 30,
    borderRadius: "10px",
    fontFamily: "Roboto",
  },

  hackathonText: {
    paddingTop: "20px",
    display: "flex",
    justifyContent: "center",
  },

  hackathonHead: {
    fontSize: "42px",
    position: "relative",
    margin: "0",
    boxSizing: "border-box",
    color: "#24252A",
    "&:before": {
      left: "0",
      width: "100%",
      boxSizing: "border-box",
      bottom: "5px",
      height: "10px",
      opcity: "1",
      content: '""',
      zIndex: "0",
      position: "absolute",
      background: "#ee3342",
      opacity: "30%",
      textAlign: "center",
      justifyContent: "center",
      [theme.breakpoints.down("sm")]: {
        width: "0px",
      },
      [theme.breakpoints.up("sm")]: {
        width: "100%",
        bottom: "5px",
        marginLeft: "0px",
      },
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "24px",
    },
  },

  hackathonDescriptionText: {
    color: "#24252A",
    paddingRight: "30px",
    paddingLeft: "30px",
    fontSize: "18px",
    [theme.breakpoints.down("sm")]: {
      paddingRight: "15px",
      paddingLeft: "15px",
    },
    paddingBottom: "20px",
    borderBottom: "1px solid #e3e3e3",
  },

  programFooter: {
    with: "100%",
    [theme.breakpoints.down("sm")]: {
      marginTop: "15px",
    },
  },

  programRegisterButton: {
    background: "#FF3644",
    borderRadius: 10,
    border: 0,
    color: "white",
    height: 35,
    padding: "0 30px",
    boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
    "&:hover": {
      backgroundColor: "#FF3644",
    },
  },

  hackathonDescriptionTextBold: {
    fontWeight: "bold",
    fontSize: "24px",
  },

  hackathonDescriptionTextContainer: {
    [theme.breakpoints.up("lg")]: {
      paddingLeft: "15px",
      paddingRight: "15px",
    },
  },

  evaluationCriteriaContainer: {
    flexGrow: 1,
    display: "grid",
    gridTemplateColumns: "auto",
    justifyContent: "center",
    alignItems: "center",
  },

  evaluationCriteriaListContainer: {
    margin: "0px",
    paddingLeft: "0px",
    paddingRight: "0px",
    marginTop: "10px",
  },

  evaluationCriteriaList: {
    listStyle: "none",
  },

  evaluationCriteriaHead: {
    margin: 0,
  },

  evaluationCriteriaTwoColumns: {
    display: "grid",
    gridTemplateColumns: "auto auto",
    columnGap: "10px",
    rowGap: "10px",
    paddingRight: "30px",
    paddingLeft: "30px",
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  },

  evaluationCriteriaDescription: {
    marginTop: "0px",
  },
  evaluationCriteriaHead: {
    margin: 2,
  },
}));

export default function Program(props) {
  const classes = useStyles(props);
  return (
    <div className={classes.hackathonContainer} id="hackathon">
      <div className={classes.hackathonText}>
        <h2 className={classes.hackathonHead}>
          Setur Travel Smart Tech Hackathon
        </h2>
      </div>

      <div className={classes.hackathonDescriptionTextContainer}>
        <p className={classes.hackathonDescriptionText}>
          Turizmle alakalı açık veri sağlayan çeşitli sitelerden temin
          edebileceğiniz verileri kullanarak seyahat tercihleriyle ilgili farklı
          ilişkileri keşfetme konusunda yeni yaklaşımlarınızı bekliyoruz.
          Elinizdeki veriler üzerinde fikirlerinizi, yeteneklerinizi ve
          bilginizi kullanacağınız Smart Tech yarışmasında yerinizi alın.
        </p>
      </div>

      <div className={classes.evaluationCriteriaContainer}>
        <h2>Değerlendirme Kriterleri</h2>
        <div className={classes.evaluationCriteriaTwoColumns}>
          <div className={classes.evaluationCriteriaColumnContainer}>
            <div className={classes.evaluationCriteriaContainer}>
              <h4 className={classes.evaluationCriteriaHead}>Fikir</h4>
              <p className={classes.evaluationCriteriaDescription}>
                Fikriniz Neden Değerli? Ne kadar yenilikçi? Fikir ne kadar
                uygulanabilir? Turizm sektörüne ne kadar uygun?
              </p>
            </div>

            <div className={classes.evaluationCriteriaContainer}>
              <h4 className={classes.evaluationCriteriaHead}>
                Yapılabilirlik ve Etki
              </h4>
              <p className={classes.evaluationCriteriaDescription}>
                Fikrin hayata geçmesi için kaynaklar nelerdir? Hayata
                geçirildiğinde nasıl bir etkisi olacak?
              </p>
            </div>
          </div>

          <div className={classes.evaluationCriteriaColumnContainer}>
            <div className={classes.evaluationCriteriaContainer}>
              <h4 className={classes.evaluationCriteriaHead}>
                MVP / Tasarlanan ürün
              </h4>
              <p className={classes.evaluationCriteriaDescription}>
                Fikir sonucu ortaya çıkan ürün çözüme ne kadar uygun? Hangi
                teknolojiler ne düzeyde kullanıldı?
              </p>
            </div>

            <div className={classes.evaluationCriteriaContainer}>
              <h4 className={classes.evaluationCriteriaHead}>Sunum ve Demo</h4>
              <p className={classes.evaluationCriteriaDescription}>
                Sunumun zamanında teslim edildi mi? Sunum yetkinliği ne düzeyde
                idi?
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className={classes.programFooter}>
        <p className={classes.hackathonDescriptionTextBold}>
          10 Aralık 2020 kayıtlar için son gün!
        </p>
        <Link to="/kaydol-setur-hackathon" style={{ textDecoration: "none" }}>
          <Button className={classes.programRegisterButton}>Kaydol</Button>
        </Link>
      </div>
    </div>
  );
}
