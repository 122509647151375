import React from 'react'
import {makeStyles} from '@material-ui/core/styles'
import { PinDropSharp } from '@material-ui/icons'

const useStyles = makeStyles((theme) => ({
    forCompetitorsContainer:{
        backgroundColor: "#ee3342",
        with: "100vh",
        paddingBottom: "50px",
        flexGrow: 1,
        justifyContent: "center",
        marginTop: 30,
        borderRadius: "10px",
    },

    forCompetitorsText:{
        paddingTop: "20px",
        display: "flex",
        justifyContent: "center",
    },

    forCompetitorsHead:{
        fontSize: "42px",
        position: "relative",
        margin: "0",
        boxSizing: "border-box",
        color: "#FFF",
        [theme.breakpoints.down('sm')]: {
            fontSize: "24px",
        },
    },

    forCompetitorsDescriptionText:{
        color: "#FFF",
        paddingRight: "30px",
        paddingLeft: "30px",
        fontSize: "24px",
        [theme.breakpoints.down('sm')]: {
            paddingRight: "15px",
            paddingLeft: "15px",
        },
    },
}))

export default function ForCompetitors(props) {
    const classes = useStyles(props)
    return (
        <div className={classes.forCompetitorsContainer}>
            <div className={classes.forCompetitorsText}>
                <h2 className={classes.forCompetitorsHead}>Yarışmacıları Neler Bekliyor?</h2>
            </div>

            <div className={classes.forCompetitorsDescriptionTextContainer}>
                <p className={classes.forCompetitorsDescriptionText}>Setur'dan toplam 16.000₺ nakit ödül</p>
                <p className={classes.forCompetitorsDescriptionText}>Toplam 3.400$'a varan AWS kredisi</p>
                <p className={classes.forCompetitorsDescriptionText}>Jüri özel ödülü olarak KWORKS ön hızlandırma programına katılma hakkı</p>
                <p className={classes.forCompetitorsDescriptionText}>Mentorluk desteği</p>
                <p className={classes.forCompetitorsDescriptionText}>Ürün geliştirme ve sunum eğitimleri</p>
            </div>
            
        </div>
    )
}
