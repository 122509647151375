import React from 'react'
import { makeStyles } from "@material-ui/core/styles"
import Button from '@material-ui/core/Button'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { Link } from "react-router-dom"

const useStyles = makeStyles((theme) => ({
    navigationContainer:{
        backgroundColor: "#FFF",
        with: "100vh",
        paddingTop: "20px",
        justifyContent: "space-between",
        alignItems: "center",
    },

    rightContainer:{
        borderRadius: "10px"
    },

    brandContainer:{
        display: "flex"
    },

    headerLeftImage:{
        height: "50px",
        [theme.breakpoints.down('lg')]: {
            marginLeft: "20px",
        },
        [theme.breakpoints.down('sm')]: {
            height: "30px"
        },
    },

    navigationHeadText:{
        lineHeight: "47px",
        [theme.breakpoints.down('sm')]: {
            fontSize: "18px",
            lineHeight: "10px",
        },
    },

    arrow: {
        fontSize: "25px",
    },
    backToHomeButton:{
        border: 0,
        color: '#000',
        height: 45,
        '&:hover': {
            backgroundColor: '#FFF',
        },
    },

    backToHomeContainer:{
        textAlign: "left",
        paddingBottom: 30,
    }

}))

export default function Navigation(props) {
    const classes = useStyles(props)

    
    return (
        <div className={classes.wrapper}>
            <div className={classes.navigationContainer}>  
                <div className={classes.backToHomeContainer}>
                <Link to="/" style={{ textDecoration: 'none' }}>
                    <Button className={classes.backToHomeButton}>
                        <ChevronLeftIcon className={classes.arrow} />Anasayfa
                    </Button>
                </Link>
                </div>
                <div className={classes.brandContainer}>
                    <img className={classes.headerLeftImage} src={process.env.PUBLIC_URL + '/static/img/logo/setur-logo.png'} alt="" />
                    <h3 className={classes.navigationHeadText}>Smarttech Hackathon</h3>
                </div>
            </div>
        </div>
    )
}
