import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  headerContainer: {
    backgroundColor: "#FFF",
    width: "100%",
    textAlign: "left",
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
    [theme.breakpoints.up("sm")]: {
      flexGrow: 1,
      display: "grid",
      gridTemplateColumns: "auto auto",
    },
  },

  headerLeft: {
    paddingTop: "80px",
    alignItems: "center",
  },
  headerSlogan: {
    fontSize: "36px",
    position: "relative",
    margin: "0",
    boxSizing: "border-box",
    display: "inline-block",
    width: "-webkit-fit-content",
    [theme.breakpoints.down("sm")]: {
      fontSize: "44px",
    },
  },

  headerDescription: {
    margin: "5",
  },

  headerRegisterButton: {
    marginTop: "50px",
    background: "#FF3644",
    borderRadius: 10,
    border: 0,
    color: "white",
    height: 45,
    width: 175,
    boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
    "&:hover": {
      backgroundColor: "#FF3644",
    },

    [theme.breakpoints.down("sm")]: {
      marginTop: "20px",
    },
  },

  headerRight: {
    marginTop: "80px",
    display: "flex",
    justifyContent: "space-between",
  },
  headerRightImage: {
    borderRadius: "10px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: "300px",
    },
    [theme.breakpoints.up("md")]: {
      width: "100%",
      height: "400px",
    },
    [theme.breakpoints.up("lg")]: {
      width: "100%",
      height: "350px",
    },
  },

  /* underlineSpan:{
        '&:before': {
            left: "0",
            boxSizing: "border-box",
            height: "10px",
            opcity: "1",
            content: '""',
            zIndex: "0",
            position: "absolute",
            background: "#ee3342",
            opacity: "50%",
            
            [theme.breakpoints.up('xs')]: {
                width: "65%",
                top: "42px",
            },

            [theme.breakpoints.up('sm')]: {
                width: "60%",
                top: "42px",
            },
            
            [theme.breakpoints.up('md')]:{
                width: "100%",
                top: "30px",
            },
        },
    } */
  deadline: {
    fontWeight: "bold",
  },
}));

export default function Header(props) {
  const classes = useStyles(props);

  return (
    <div className={classes.headerContainer}>
      <div className={classes.headerLeft}>
        <h1 className={classes.headerSlogan}>
          <span className={classes.underlineSpan}>Setur Travel Smarttech</span>
        </h1>
        <br />
        <h1 className={classes.headerSlogan}>Hackathon</h1>
        <p className={classes.headerDescription}>
          Fark yaratan seyahat fikirleri, akıllı turizm pazarlaması, akıllı
          tavsiye sistemleri ve müşteri odaklı öğrenen yapay zekâ uygulamalarını
          geliştirmek için hazır mısınız?
        </p>
        <p className={classes.headerDescription}>
          <b>14 - 30 Aralık 2020 </b>tarihleri arasında düzenlenecek olan Setur
          Travel Smart Tech’a davetlisiniz.
        </p>
        <p className={classes.headerDescription}>Etkinlik sona ermiştir</p>
        <p className={classes.deadline}>Son başvuru tarihi: 10 Aralık 2020</p>
        <Link to="/kaydol-setur-hackathon" style={{ textDecoration: "none" }}>
          <Button className={classes.headerRegisterButton}>Kaydol</Button>
        </Link>
      </div>
      <div className={classes.headerLeft}>
        <img
          className={classes.headerRightImage}
          src={process.env.PUBLIC_URL + "/static/img/header-2.jpg"}
          alt=""
        />
      </div>
    </div>
  );
}
